import {
  AppLogoIcon,
  Body,
  Box,
  Button,
  Divider,
  GoogleIcon,
  Heading,
  Spacer,
  Text,
  useToast,
  VBox,
  ViewPort,
} from "@app/ui";
import { PageProps } from "gatsby";
import React, { useEffect, useRef, useState } from "react";

import { redirect } from "@app/components/Auth";
import {
  isConflictError,
  isValidationError,
  useRequestLogin,
} from "@app/queries";
import { ExtLink, Link } from "@app/site";
import { EmailLoginForm } from "@app/ui/Form";
import { useWindowSize } from "@app/ui/hooks";
import { SEO } from "@app/ui/seo";
import { Checkbox } from "@chakra-ui/checkbox";
import { QueryClient, QueryClientProvider } from "react-query";
import { hasActiveSession, navigateExt, openPopupWindow } from "shared/utils";

export const SignupForm = ({ location, title }) => {
  const [hasSession, setHasSession] = useState<boolean>(true);

  const [consent, setConsent] = useState<boolean>(false);

  const { success, failure } = useToast(5000);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const hasSession = hasActiveSession();
    setHasSession(hasSession);
    if (hasSession)
      navigateExt(`${process.env.GATSBY_APP_BASE_URL}/app`, { replace: false });
  }, []);

  const loginPopupName = "app-signup";

  const receiveMessage = useRef((event) => {
    if (event.origin !== process.env.GATSBY_SITE_BASE_URL) {
      return;
    }
    const { data, source } = event;

    if (source && source?.name === loginPopupName) {
      const params = new URLSearchParams(data);
      if (
        params.has("error_desc") &&
        params.get("error_desc") === "ConflictError"
      ) {
        failure(
          "Account already exist. Please log in, or Sign up with another account."
        );
      } else {
        redirect({ params: data });
      }
    }
  });

  const mutation = useRequestLogin(
    (data) => {
      success("Verification email sent");
    },
    (err) => {
      console.log(err);

      if (isConflictError(err)) {
        failure(
          "Account already exists. Please log in, or sign up with another email."
        );
      } else if (isValidationError(err)) {
        failure("Not allowed email address. Please use another one.");
      } else {
        failure("Failed to request email verification. Please retry later.");
      }
    }
  );

  const { height } = useWindowSize();

  return !hasSession ? (
    <ViewPort minHeight={"650px"}>
      <Body align="center">
        <Box marginTop={10}>
          <Link to="/" ignoreHover={true} display="inline-block" mb={20}>
            <AppLogoIcon boxSize={36} maxH="70px" />
          </Link>
        </Box>

        <Box marginTop={7}>
          <Heading size="lg">{title}</Heading>
        </Box>

        <Checkbox
          mt={16}
          pl={3}
          checked={consent}
          onChange={() => setConsent(!consent)}
          // size="lg"
        >
          <Text
            color={"gray.500"}
            fontSize={"medium"}
            align="center"
            maxW={"300px"}
          >
            I understood, and agree to DeckedLink's{" "}
            <ExtLink href="/privacy" hoverStyle={"unstyled"} underline blank>
              Privacy Policy
            </ExtLink>
            {" & "}
            <ExtLink href="/privacy" hoverStyle={"unstyled"} underline blank>
              Terms of Service
            </ExtLink>
          </Text>
        </Checkbox>
        <VBox marginTop={10}>
          <Button
            mb={3}
            pl={3}
            backgroundColor={"red.600"}
            borderRadius={4}
            color={"white"}
            colorScheme={"red.600"}
            cursor="pointer"
            onClick={() =>
              openPopupWindow(
                `${process.env.GATSBY_API_URL}/auth/google/signup${
                  params.has("p")
                    ? `?p=${encodeURIComponent(params.get("p"))}`
                    : ""
                }`,
                loginPopupName,
                receiveMessage.current
              )
            }
            disabled={!consent}
          >
            <GoogleIcon boxSize={4} marginRight={2} />
            {" Continue with Google"}
          </Button>
          <Spacer h={5} />
          <Divider orientation="horizontal" />
          <EmailLoginForm
            consent={consent}
            onSubmit={async (inputs: any) => {
              const { email } = inputs;
              try {
                await mutation.mutateAsync({
                  email,
                  action: "signup",
                });
              } catch (err) {}
            }}
          />
        </VBox>

        <Box maxWidth={height} padding={[3, 6]} marginTop={"auto"}>
          <Text color={"gray.500"} align="center">
            Already have an account?{" "}
            <Link to="/login" fontWeight={"semibold"}>
              Log in
            </Link>
          </Text>
        </Box>
      </Body>
    </ViewPort>
  ) : null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function SignupPage({ location }: PageProps) {
  return (
    <>
      <SEO title="Sign up for DeckedLink" />
      <QueryClientProvider client={queryClient}>
        <SignupForm location={location} title={"Sign up for early access"} />
      </QueryClientProvider>
    </>
  );
}

export default SignupPage;
